<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-6 offset-3">
        <div class="card mb-5">
          <div class="card-header">
            <h5 class="mt-1 mb-0">
              Upload {{ name }}
            </h5>
          </div>
          <div class="card-body">
            <form id="mapping-form">
              <div class="mb-3">
                <label
                  class="form-label"
                  for="mapping-file"
                >Excel (<span class="text-info">.csv</span>) file:</label>
                <input
                  ref="file"
                  class="form-control"
                  required
                  type="file"
                />

                <div
                  v-if="name.toLowerCase().includes('tsheets')"
                  class="mt-3 text-warning small"
                >
                  <div>
                    Upload of this mapping will trigger re-aggregation. It will be done at night (EST time)
                  </div>
                  <div class="mt-2">
                    Previous version of this mapping will be overwritten with uploaded one.
                  </div>
                </div>

                <div
                  v-if="name.toLowerCase().includes('company type')"
                  class="mt-3 text-warning small"
                >
                  <div class="mt-2">
                    <div>
                      Upload will save old versions of this mapping for re-aggregation.
                    </div>
                    <div class="mt-2">
                      Uploaded version will be applied from the next aggregation.
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="card-footer">
            <div class="float-end">
              <button
                class="btn btn-primary"
                @click="uploadMapping"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h5 class="mt-1 mb-0">
              Download {{ name }}
            </h5>
          </div>
          <div class="card-body">
            <div class="text">
              The latest version of the mapping will be downloaded
            </div>
          </div>
          <div class="card-footer">
            <div class="float-end">
              <button
                class="btn btn-primary"
                @click="downloadMapping"
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UploadMapping',
  props: [ 'name', 'uploadUrl', 'downloadUrl' ],
  computed: {
    ...mapGetters({ authToken: 'auth/getToken' })
  },
  watch: {
    name: function (newVal, oldVal) {
      if (newVal !== oldVal) this.$refs.file.value = null;
    }
  },
  methods: {
    async uploadMapping() {
      const ext = this.$refs.file?.files[0]?.name.split('.').pop().toLowerCase();
      const uploadXlsxRoutes = [ 'TSheets Mapping', 'Company Type Mapping' ];
      const uploadCsvRoutes = ['Shiphero Report'];

      if (!ext) {
        this.$toast.show('Please, provide a file.', { type: 'error' });
        return;
      } else {
        if (uploadXlsxRoutes.includes(this.name) && ext !== 'csv') {
          this.$toast.show(
            'Please, provide a valid sheet file with .csv extension.',
            { type: 'error' }
          );
          return;
        }
        if (uploadCsvRoutes.includes(this.name) && ext !== 'csv') {
          this.$toast.show(
            'Please, provide a valid sheet file with .csv extension.',
            { type: 'error' }
          );
          return;
        }
      }

      const formData = new FormData();
      formData.append('file', this.$refs.file.files[0]);

      try {
        const res = await fetch(`${process.env.VUE_APP_BASE_URL}${this.uploadUrl}`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Authorization': `Bearer ${this.authToken}`
          },
          body: formData
        });
        if ([ 200, 202 ].includes(res?.status)) {
          this.$toast.show('The file has been uploaded.', { type: 'success' });
        } else {
          this.$toast.show('Please, provide a valid file.', { type: 'error' });
        }
      } catch {
        this.$toast.show('Failed to upload the file. Please, try again.', { type: 'error' });
      }
    },
    async downloadMapping() {
      try {
        const res = await fetch(`${process.env.VUE_APP_BASE_URL}${this.downloadUrl}`, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Authorization': `Bearer ${this.authToken}`
          }
        });
        if (res?.status !== 200) {
          this.$toast.show('Failed to download the file. Please, try again later.', { type: 'error' });
          return;
        }
        const filename = res.headers.get('content-disposition').split('filename=')[1].split(';')[0];
        const blob = await res.blob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (e) {
        console.log(e);
        this.$toast.show('Failed to download the file. Please, try again later.', { type: 'error' });
      }
    }
  }
};
</script>
