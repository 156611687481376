<template>
  <div
    id="company-type-edit-modal"
    ref="company-type-edit-modal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ modalTitle }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="modal-body">
          <form v-if="initialized">
            <TextInput
              v-model="item.company"
              :errors="v$.item.company.$errors"
              description="Company"
              label="Company"
              @blur="v$.item.company.$touch"
            />
            <TextInput
              v-model="item.companyCode"
              :errors="v$.item.companyCode.$errors"
              description="Company Code"
              label="Company Code"
              @blur="v$.item.companyCode.$touch"
            />
            <TextInput
              v-model="item.companyType"
              :errors="v$.item.companyType.$errors"
              description="Company Type"
              label="Company Type"
              @blur="v$.item.companyType.$touch"
            />
            <TextInput
              v-model="item.daysDelay"
              :errors="v$.item.daysDelay.$errors"
              description="Days Delay"
              label="Days Delay"
              @blur="v$.item.daysDelay.$touch"
            />
            <DatePicker
              v-model="item.uploadedDate"
              :errors="v$.item.uploadedDate.$errors"
              :formatter="dateFormatter"
              description="Date from which the mapping is applied"
              label="Effective date"
              @blur="v$.item.uploadedDate.$touch"
            />
          </form>
          <Spinner v-else />
          <div class="modal-footer d-flex justify-content-between">
            <button
              :disabled="isDeleteButtonDisabled"
              class="btn btn-danger"
              @click="showDeleteConfirmationModalHandler"
            >
              Delete
            </button>
            <button
              :disabled="isSaveButtonDisabled"
              aria-label="Close"
              class="btn btn-success"
              type="submit"
              @click="showSaveConfirmationModalHandler"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmationModal
    ref="company-type-edit-confirmation-modal"
    :onCancelHandler="cancelEditHandler"
    :onConfirmHandler="saveChangesHandler"
  >
    <template #content>
      <div
        class="alert alert-warning"
        role="alert"
      >
        <h6>
          <i class="bi bi-exclamation-triangle" />
          The changes you have just made will only
          apply to all new data. Not historical data.
        </h6>
      </div>
    </template>
  </ConfirmationModal>
  <DeleteConfirmationModal
    ref="company-type-delete-confirmation-modal"
    :onCancelHandler="cancelDeleteHandler"
    :onConfirmHandler="deleteRecordHandler"
  />
</template>
<script>
import { mapActions } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { integer, required } from '@vuelidate/validators';
import { Modal } from 'bootstrap';
import ConfirmationModal from '@/components/common/ConfirmationModal';
import TextInput from '@/components/common/TextInput';
import DatePicker from '@/components/common/DatePicker';
import Spinner from '@/components/common/Spinner';
import DeleteConfirmationModal from '@/components/common/DeleteConfirmationModal';
import { dateFormatter, modalViewTypes } from '@/components/constants';

const defaultItem = {
  brandId: null,
  company: null,
  companyCode: null,
  companyType: null,
  daysDelay: null,
  uploadedDate: new Date()
};

export default {
  components: {
    DatePicker,
    ConfirmationModal,
    DeleteConfirmationModal,
    TextInput,
    Spinner
  },
  props: [ 'viewType', 'brand', 'brandCode', 'brandId' ],
  emits: [ 'successSave', 'successDelete' ],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      date: null,
      editModal: null,
      confirmationModalSave: null,
      confirmationModalDelete: null,
      initialized: false,
      item: {
        brandId: null,
        company: null,
        companyCode: null,
        companyType: null,
        daysDelay: null,
        uploadedDate: null
      }
    };
  },
  validations() {
    return {
      item: {
        company: { required },
        companyCode: {},
        companyType: { required },
        daysDelay: { required, integer },
        uploadedDate: { required }
      }
    };
  },
  computed: {
    modalTitle() {
      return this.viewType === modalViewTypes.edit ? 'Edit row' : 'Add New';
    },
    isSaveButtonDisabled() {
      return !this.initialized;
    },
    isDeleteButtonDisabled() {
      return !this.initialized || this.viewType === modalViewTypes.add;
    }
  },
  async mounted() {
    this.editModal = new Modal(this.$refs['company-type-edit-modal']);
    this.confirmationModalSave = this.$refs['company-type-edit-confirmation-modal'].confirmationModal;
    this.confirmationModalDelete = this.$refs['company-type-delete-confirmation-modal'].confirmationModal;
    this.confirmationModalSave.hide();
    this.confirmationModalDelete.hide();
  },
  methods: {
    ...mapActions({
      fetchCompanyTypeMappingItems: 'companyTypeMapping/fetchCompanyTypeMappingItems',
      fetchCompanyTypeMappingItemById: 'companyTypeMapping/fetchCompanyTypeMappingItemById',
      createCompanyTypeMappingItem: 'companyTypeMapping/createItem',
      updateCompanyTypeMappingItem: 'companyTypeMapping/updateItem',
      deleteCompanyTypeMappingItem: 'companyTypeMapping/deleteItem'
    }),
    dateFormatter(date) {
      return dateFormatter(date);
    },
    async initModal(itemId) {
      this.initialized = false;
      this.editModal.show();
      const itemToEdit = itemId ? await this.fetchCompanyTypeMappingItemById(itemId) : { ...defaultItem };
      if (!itemId && this.brand && this.brandCode && this.brandId) {
        itemToEdit.company = this.brand;
        itemToEdit.companyCode = this.brandCode;
        itemToEdit.brandId = this.brandId;
      }
      this.updateItemData(itemToEdit);
      this.initialized = true;
    },
    updateItemData(newValue) {
      this.item.id = newValue.id;
      this.item.brandId = newValue.brandId;
      this.item.company = newValue.company;
      this.item.companyCode = newValue.companyCode;
      this.item.companyType = newValue.companyType;
      this.item.daysDelay = newValue.daysDelay;
      this.item.uploadedDate = newValue.uploadedDate;
    },
    showDeleteConfirmationModalHandler() {
      this.editModal.hide();
      this.confirmationModalDelete.show();
    },
    cancelDeleteHandler() {
      this.confirmationModalDelete.hide();
      this.editModal.show();
    },
    async deleteRecordHandler() {
      try {
        await this.deleteCompanyTypeMappingItem(this.item.id);
        await this.fetchCompanyTypeMappingItems();
        this.$emit('successDelete');
      } catch (e) {
        this.$toast.error('Failed to delete item.');
      }
    },
    async showSaveConfirmationModalHandler() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$toast.error('Form should be valid.');
        return false;
      }
      this.editModal.hide();
      this.confirmationModalSave.show();
    },
    async saveChangesHandler() {
      const dataToSave = {
        ...this.item,
        uploadedDate: this.dateFormatter(this.item.uploadedDate)
      };
      try {
        if (dataToSave.id) {
          await this.updateCompanyTypeMappingItem(dataToSave);
          this.$toast.success('Item successfully updated');
        } else {
          await this.createCompanyTypeMappingItem(dataToSave);
          this.$toast.success('Item successfully created');
        }
        await this.fetchCompanyTypeMappingItems();
        this.$emit('successSave');
      } catch (e) {
        const message = e.message ? `: ${e.message}` : '';
        this.$toast.error(`Failed to save the data${message}.`);
      }
    },
    cancelEditHandler() {
      this.confirmationModalSave.hide();
    }
  }
};
</script>
<style scoped></style>
