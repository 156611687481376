<template>
  <TwoTabsLayout>
    <template #page-header>
      <h5 class=" mt-1 mb-0">
        Company Type Mapping
      </h5>
      <button
        class="btn btn-sm btn-primary"
        @click="addNewClickHandler"
      >
        Add new
      </button>
    </template>
    <template #tab-1-content>
      <div
        v-if="rowData.length"
        class="card-body p-0 w-100 d-flex justify-content-center"
        style="height: 50em;"
      >
        <ag-grid-vue
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :pagination="true"
          :paginationAutoPageSize="true"
          :rowData="rowData"
          class="ag-theme-alpine h-100 w-100"
          sideBar="filters"
        />
      </div>
      <Spinner v-else />
    </template>
    <template #tab-2-content>
      <Mapping
        :downloadUrl="bulkUploadConfig.downloadUrl"
        :name="bulkUploadConfig.name"
        :uploadUrl="bulkUploadConfig.uploadUrl"
      />
    </template>
  </TwoTabsLayout>
  <CompanyTypeMappingEditModal
    id="company-type-mapping-edit-modal"
    ref="companyTypeMappingEditModal"
    :viewType="modalViewType"
  />
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { AgGridVue } from 'ag-grid-vue3';
import AgGreedButtonCellRenderer from '@/components/common/AgGreedButtonCellRenderer';
import Mapping from '@/components/Mapping';
import CompanyTypeMappingEditModal from '@/components/CompanyTypeMapping/CompanyTypeMappingEditModal';
import Spinner from '@/components/common/Spinner';
import TwoTabsLayout from '@/components/Layouts/TwoTabsLayout';
import { modalViewTypes } from '@/components/constants';

export default {
  components: {
    TwoTabsLayout,
    Mapping,
    Spinner,
    AgGridVue,
    CompanyTypeMappingEditModal,
    // eslint-disable-next-line vue/no-unused-components
    AgGreedButtonCellRenderer
  },
  data() {
    return {
      modalViewType: null
    };
  },
  computed: {
    ...mapGetters({
      rowData: 'companyTypeMapping/companyTypeMappingItems',
      bulkUploadConfig: 'companyTypeMapping/bulkUploadConfig'
    }),
    defaultColDef() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true
      };
    },
    columnDefs() {
      return [
        {
          headerName: '',
          field: 'id',
          cellRenderer: 'AgGreedButtonCellRenderer',
          cellRendererParams: {
            clicked: (field) => {
              this.rowClickHandler(field);
            }
          },
          width: 80
        },
        {
          headerName: 'ID',
          field: 'id',
          width: 80
        },
        {
          headerName: 'Company Code',
          field: 'companyCode',
          width: 90,
          sortable: true,
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'Company',
          field: 'company',
          width: 350,
          sortable: true,
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'Company Type',
          field: 'companyType',
          width: 350,
          sortable: true,
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'Days Delay',
          field: 'daysDelay',
          width: 90,
          sortable: true,
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'Effective Date',
          field: 'uploadedDate',
          width: 200,
          sortable: true
        }
      ];
    }
  },
  async mounted() {
    await this.fetchCompanyTypeMappingItems();
  },
  methods: {
    ...mapActions({
      fetchCompanyTypeMappingItems: 'companyTypeMapping/fetchCompanyTypeMappingItems'
    }),
    async rowClickHandler(itemId) {
      this.modalViewType = modalViewTypes.edit;
      await this.$refs.companyTypeMappingEditModal.initModal(itemId);
    },
    addNewClickHandler() {
      this.modalViewType = modalViewTypes.add;
      this.$refs.companyTypeMappingEditModal.initModal();
    }
  }
};
</script>
